import React from 'react';
import * as styles from './Card.module.css';
import { Link } from 'gatsby';

function Card({
  description,
  img,
  title,
  link='/',
}) {
  return(<div className={styles.card}>
    <Link to={link}>
      <img src={img} alt={title} className={styles.image} />
    </Link>
    <Link to={link}>
      <h2 className={styles.cardTitle}>{title}</h2>
    </Link>
    <p className={styles.cardDescription}>{description}</p>
  </div>);
}

export default Card;
