import React  from 'react';
import { Link } from 'gatsby';

import * as styles from './Header.module.css';
import Nav from '../components/Nav';

function Header({
  Banner,
}) {
  return(<div className={styles.header}>
    <div className={styles.band}>
      <div className={styles.bandInner}>
        <div className={styles.logo}>
          <h1>
            <Link to="/">
              <span>
                Access Aviation
              </span>
            </Link>
          </h1>
        </div>
        <h2 className={styles.tagline}>
          GLOBAL TRANSPORTATION SOLUTIONS
        </h2>
        <div />
      </div>
    </div>

    {Banner &&
      <div style={{maxWidth: '1002px', margin: '0 auto'}}>
        <Banner />
      </div>
    }

    <Nav push={!Banner}/>

  </div>);
}

export default Header;
