import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Layout, { Primary, Container, Content, Callout } from '../components/Layout';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Main from '../templates/Main';

import img001 from '../images/wp-uploads/2013/04/16951_JFK_Facility_Spec_A07-1.jpg';
import img002 from '../images/wp-uploads/2013/04/16951_JFK_Facility_Spec_A07-2.jpg';
import img003 from '../images/wp-uploads/2013/04/16951_JFK_Facility_Spec_A07-3.jpg';
import img004 from '../images/wp-uploads/2013/04/16951_JFK_Facility_Spec_A07-4.jpg';
import img005 from '../images/wp-uploads/2013/04/16951_JFK_Facility_Spec_A07-5.jpg';
import img006 from '../images/wp-uploads/2013/04/16951_JFK_Facility_Spec_A07-6.jpg';

const images = [img005, img004, img003, img006, img001, img002];

const specs = [
  'Corporate Aviation Facility: ±80,000 SF (8.4 acres)',
  'Fixed-wing Hangar: ±32,000 SF / 141’ x 224’ x (41’-34’)',
  'Pax. Vehicle / Helicopter Hangar: ±4,500 SF / 60’ x 75’ x 20’',
  'Office / Lab / Work / Storage: ±45,500 SF',
  'Fuel farm – 6 dual pump filtration fiberglass 20,000 gallon underground fuel tanks',
  'Back up generator fed by 350 gallon Jet A Fuel tank',
  'Fuel cabinet with one single point and one over wing nozzle',
  '2 ton crane in helicopter hangar',
  '5 ton crane in main hangar',
  'Hangar doors designed to withstand 90 MPH winds (45 PSF)',
  'Ventilation system in main hangar',
  'IS-BAO – stage II completed; stage III on hold',
  'Hoist in hangar to 2nd floor with 2 ton crane',
  'Card reader at security gate',
  'Numerous 3 phase 220 VAC outlets to power GPU’s throughout hangar',
  'Paint booth room off hangar',
  'Double wide plastic heat blaster in hangar annex',
  'Magnaflux and dye penatrant machines/equipment in overhaul shop',
  'Guard station has HVAC and restroom',
  'Site suitable for emergency response center',
];

function ContactUsPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation</title>
    </Helmet>
    <Header />
    <Content>
      <Container>
        <Gallery
          title="IAH Aviation Facility – Lease Opportunity"
          images={images}
        />
        <Primary title="Facility Specifications">
          <Layout>
            <div>
              <ul>
                {specs.map((spec, idx) => {
                  return <li key={idx} style={{ margin: '5px 0'}}>{spec}</li>
                })}
              </ul>
            </div>
            <div>
              <Callout>
                <strong>
                  IAH Corporate Aviation Facility
                </strong>
                <br />
                <strong>Status: <span>LEASE OPPORTUNITY</span></strong>
                <br />
                <a href="http://www.accessaviation.com/wp-content/uploads/2013/05/16951_JFK_Facility_Spec_A09.pdf" target="_blank" rel="noreferrer">
                  Click here to download the PDF
                </a>
              </Callout>
            </div>
          </Layout>
        </Primary>
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default ContactUsPage;
