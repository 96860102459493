import React from 'react';
import { Helmet } from 'react-helmet';
import * as styles from './about-us.module.css';
import Header from '../components/Header';
import Layout, { Primary, CalloutListings, Container, Content } from '../components/Layout';
import Footer from '../components/Footer';
import Hero from '../components/heros/AboutUs';
import Main from '../templates/Main';

import imgBLiddell from '../images/wp-uploads/2013/03/BobLiddell_3C-17_130x164.jpg';
import imgMHiggens from '../images/wp-uploads/2011/01/Screen-Shot-2020-04-20-at-10.55.57-PM.png';

function AboutUsPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation | About us</title>
    </Helmet>
    <Header Banner={Hero} />
    <Content>
      <Container>
        <Layout>
          <Primary title="About us">
            <div>
              <p><strong>Access</strong> is a full service aircraft brokerage, sales and project management company who has earned a reputation for integrity, personalized service and a rigorous commitment to safety. Access is committed to ensuring that every solution exceeds client expectations.</p>
              <p><strong>Access</strong> has a commitment to achieving client goals and works collaboratively in multiple geographic locations. The company is responsive, efficient, accountable and reinforces a corporate culture that values leaders who are devoted to client satisfaction.</p>
              <p><strong>Access</strong> earned it’s expertise by holding key positions in support of some of the world’s most innovative and sophisticated clients. With a diverse and valuable set of core capabilities, Access has an unmatched depth of knowledge and business culture.</p>
              <hr />
              <h2>ROBERT LIDDELL</h2>
              <h6><em>Managing Partner</em></h6>
              <p>
                <img className={styles.image} src={imgBLiddell} alt="Bob Liddell" />
                Bob Liddell, a business aviation executive with 30 years of administrative and operations experience, managed one of the largest fleets of corporate aircraft operated by a Fortune company. He has an extensive background in international operations, transportation initiatives and financial reporting that was developed while overseeing 13 corporate aviation facilities, 50 aircraft and an energy-focused staff of 250 personnel.
              </p>
              <p>As GM and Transportation Director for Tenneco, Bob held significant risk management responsibilities for marketing and selling highly personalized on demand global executive transportation services that included foreign based aircraft, offshore helicopter operations and scheduled high density shuttle flights to eight major business units each averaging 4+B in sales. His management team negotiated, purchased and operated (worldwide) Gulfstream, Falcon, Boeing, British Aerospace, and Citation aircraft with no surprises accountability. Additionally, the team developed standardization and training programs for over 100 flight crew members along with support for an FAA Part 145 maintenance repair station and multiple Qualitron FBOs. Special projects included design and construction of various aviation facilities from 30 to 90 thousand square feet; as well as long term leases with airport authorities that included underground fuel storage tank compliance.</p>
              <p>Bob maintains a complete infrastructure of tools and processes to quickly take operations, in-flight, security, marketing and administrative teams with P&amp;L accountability to their highest competitive level in safety, cost effectiveness and customer service. He works with several fractional providers and has performed many FAR 135 operational audits. In his community service work Bob provided maintenance, operations and fund raising support for Project Orbis (the flying DC-10 teaching eye hospital).</p>
              <hr />
              <h2>MICHAEL HIGGINS</h2>
              <h6><em>VP of Business Development</em></h6>
              <p>
                <img className={styles.image} src={imgMHiggens} alt="Michael Higgins" width="181" height="182" />
                Michael is the director of business development for Access Aviation in Houston with 30+ years in commercial and private aviation. He began his career with Continental Airlines and moved into private aviation where he combines his passion for aviation with his unique ability to visualize and identify business opportunities. Michael is a firm believer in creating lasting relationships with his customers always remembering that the sale is only half of the equation.&nbsp;&nbsp;
              </p>
              <p>Before joining Access Aviation, he was Director of Aircraft Sales &amp; Acquisitions at Million Air at Houston Hobby where he also provided support in jet card and charter sales. In addition, Mike’s family has been in the oil &amp; gas industry for over 65 years and has allowed him to develop an extensive network of contacts and relationships throughout Houston and the State of Texas.</p>
            </div>
          </Primary>
          <CalloutListings />
        </Layout>
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default AboutUsPage;
