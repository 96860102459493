import React from 'react';
import * as styles from './index.module.css';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Layout, { Primary, Callout, Content, Container } from '../components/Layout';
import Footer from '../components/Footer';
import Card from '../components/Card';
import ImgAircraft from '../images/aircraftManagement_300x200.jpg';
import ImgAcquisitions from '../images/listings-acquisitions_300x200.jpg';
import ImgAviation from '../images/facilities001_300x200.jpg';
import Hero from '../components/heros/Home';
import Main from '../templates/Main';

const cards = [{
    description: 'Considering the acquisition or sale of a new or pre-owned jet? Exploring whether fractional ownership or jet membership is best suited to meet your travel needs? Access will deliver powerful, cost savings solutions to support your requirements.',
    link: '/listings-acquisitions',
    img: ImgAcquisitions,
    title: 'Listings & Acquisitions',
  }, {
    description: 'Perfecting aircraft management solutions for corporations and individuals is a service provided by Access that offers maximum service and efficiency.',
    link: '/aircraft-management',
    img: ImgAircraft,
    title: 'Aircraft Management',
  }, {
    description: 'Our strategic approach uncovers the hidden value and the hidden risk in aviation real estate investments and opportunities.',
    link: '/aviation-facilities',
    img: ImgAviation,
    title: 'Aviation Facilities',
  }
];

function IndexPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation</title>
    </Helmet>
    <Header Banner={Hero} />
    <Content>
      <Container>
        <Layout>
          <Primary title="Value driven global access">
            <p>Access Aviation (Access), a full-service aircraft brokerage, sales and project management company; is a leader in the acquisition, sale and management of corporate aircraft. With 30 years of global Fortune 500 experience, Access offers clients value driven no surprises accountablity in the world of private jet travel. Access also provides extensive charter brokering for clients in the US and Europe.</p>
          </Primary>
          <Callout />
        </Layout>
        <div className={styles.cards}>
          {cards.map((card, idx) => {
            return(<Card {...card}
              key={idx}
            />);
          })}
        </div>
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default IndexPage
