import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import { Container, Content } from '../components/Layout';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Main from '../templates/Main';

import img001 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0093-01a_540x358.jpg';
import img002 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0093-03a_540x360.jpg';
import img003 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0093-07a_540x360.jpg';

const images = [img001, img002, img003];

function ContactUsPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation</title>
    </Helmet>
    <Header />
    <Content>
      <Container>
        <Gallery
          title="Citation Sovereign (93)"
          images={images}
        />
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default ContactUsPage;
