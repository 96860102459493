import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import { Container, Content } from '../components/Layout';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Main from '../templates/Main';

import img001 from '../images/wp-uploads/2011/09/AA022511_GIVSP_1334-Ext_540x360.jpg';
import img002 from '../images/wp-uploads/2011/09/AA042010_G-IVSP_1334-02_540x360.jpg';
import img003 from '../images/wp-uploads/2011/09/AA022511_GIVSP_1334-Int3_540x385.jpg';
import img004 from '../images/wp-uploads/2011/09/AA022511_GIVSP_1334-Int7_540x360.jpg';

const images = [img001, img002, img003, img004];

function ContactUsPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation</title>
    </Helmet>
    <Header />
    <Content>
      <Container>
        <Gallery
          title="Gulfstream IV-SP (1334)"
          images={images}
        />
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default ContactUsPage;
