import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Layout, { Primary, CalloutListings, Container, Content } from '../components/Layout';
import Footer from '../components/Footer';
import Hero from '../components/heros/ContactUs';
import Main from '../templates/Main';

function ContactUsPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation</title>
    </Helmet>
    <Header Banner={Hero} />
    <Content>
      <Container>
        <Layout>
          <Primary title="Contact us">
            <div>
              <p>Please feel free to contact <strong className="blue">Access</strong> with any questions you may have.</p>

              <p>
                Access Aviation<br />
                2210 Pine Bend<br />
                Houston , TX 77339<br />
                (281) 770-7726
              </p>
              <hr />
              <p>
                Bob Liddell<br />
                Managing Partner<br />
                (281) 770-7726<br />
                <a href="mailto:bob@accessaviation.com" target="_blank" rel="noreferrer">bob@accessaviation.com</a>
              </p>
              <hr />
              <p>
                Michael Higgins<br />
                VP Business Development<br />
                (713) 256-3180<br />
                <a href="mailto:mike@accessaviation.com" target="_blank" rel="noreferrer">mike@accessaviation.com</a>
              </p>
            </div>
          </Primary>
          <CalloutListings />
        </Layout>
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default ContactUsPage;
