import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function Home() {
  return (
    <StaticQuery
      query={graphql`
        query {
          img: file(relativePath: { eq: "management_1000x270.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }`
      }
      render={
        (data) => <Img fluid={[data.img.childImageSharp.fluid]} />
      }
    />
  )
}

export default Home
