import React from 'react';
import * as styles from './Nav.module.css';
import { Link } from 'gatsby';

function Nav({
  push
}) {

  const pushStyle = push ? {marginLeft: '310px'} : {};

  return(
    <div className={styles.container}>
      <ul className={styles.nav}>
        {!push &&
          <Link to="/">
            Home
          </Link>
        }
        <Link to="/listings-acquisitions" style={pushStyle}>
          Listings & Acquisitions
        </Link>
        <Link to="/aircraft-management">
          Aircraft Management
        </Link>
        <Link to="/aviation-facilities">
          Aviation Facilities
        </Link>
        <Link to="/about-us">
          About Us
        </Link>
        <Link to="/contact-us">
          Contact Us
        </Link>
      </ul>
    </div>
  );
}

export default Nav;
