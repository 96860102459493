import React from 'react';
import * as styles from './listings-acquisitions.module.css';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import Header from '../components/Header';
import Layout, { Primary, Callout, Container, Content } from '../components/Layout';
import Footer from '../components/Footer';
import Hero from '../components/heros/ListingsAcquisitions';
import Main from '../templates/Main';

import img001 from '../images/wp-uploads/2013/04/16951_JFK_Facility_Spec_A07-5.jpg';
import img002 from '../images/wp-uploads/2011/09/AA022511_GIVSP_1334-Ext_540x360-300x200.jpg';
import img003 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0141-02a_540x358-300x198.jpg';
import img004 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0093-01a_540x358-300x198.jpg';
import img005 from '../images/wp-uploads/2015/03/flw0913_gulfstream_sm.jpg';
import img006 from '../images/wp-uploads/2011/09/AA030111_Cit_ExtSide-111-01a_534x360.jpg';
import img007 from '../images/wp-uploads/2011/01/GulfstreamIVSP_1404_ext01_sm.jpg';
import img008 from '../images/wp-uploads/2011/01/Hawker-800a.jpg';
import img009 from '../images/hawker-750-hb-68.jpg';
import img010 from '../images/gulfstream-III-424.jpg';

const listings = [
  {
    img: img001,
    status: 'Leased',
    tag: 'For lease',
    title: 'IAH Corporate Aviation Facility',
  },
  {
    img: img002,
    tag: 'Acquisiton',
    title: 'Gulfstream IV-SP (1334)',
  },
  {
    img: img003,
    tag: 'Acquisiton',
    title: 'Citation Sovereign (141)',
  },
  {
    img: img005,
    status: 'Leased',
    tag: 'For lease',
    title: 'Gulfstream G650',
  },
  {
    img: img006,
    status: 'Sold',
    tag: 'For sale',
    title: 'Citation S/11 (111)',
  },
  {
    img: img007,
    status: 'Sold',
    tag: 'For sale',
    title: 'Gulfstream IV-SP (1404)',
  },
  {
    img: img009,
    tag: 'Acquisition',
    title: 'Hawker 750 (HB-68)',
  },
  {
    img: img010,
    tag: 'Acquisition',
    title: 'Gulfstream III (424)',
  },
];


function Tag({status, tag}) {

  const statusStyle = {
    fontWeight: 'bold',
    verticalAlign: 'super',
    fontSize: '11px',
    paddingLeft: '4px',
    color: '#900',
  };

  let styles = {};
  if (status?.length > 0) {
    styles.textDecoration = 'line-through';
  }

  return(<div>
    <span style={styles}>
      {tag}
    </span>
    {!!status &&
      <span style={statusStyle}>{status}</span>
    }
  </div>);
}

function ListingCard({
  img,
  path,
  status,
  tag,
  title,
}) {

  return(
    <div>
      {path ?
        <Link to={path} className={styles.cardTitle}>
          {title}
        </Link>
        :
        <div className={styles.cardTitle}>
          {title}
        </div>
      }
      <div className={styles.cardContent}>
        <div>
          {tag && <Tag status={status} tag={tag}/>}
          {path &&
            <Link to={path}>
              Learn more...
            </Link>
          }
        </div>
        {path ?
          <Link
            className={styles.cardImage}
            style={{backgroundImage: `url(${img})`}}
            to={path}
          />
          :
          <div
            className={styles.cardImage}
            style={{backgroundImage: `url(${img})`}}
          />
        }
      </div>
    </div>
  );
}

function ListingsAcquisitionsPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation - Listings & Acquisitions</title>
    </Helmet>
    <Header Banner={Hero} />
    <Content>
      <Container>
        <Layout>
          <Primary title="Listings & Acquisitions">
            <p>Access specializes in new and pre-owned aircraft transactions, aircraft management, and other aircraft ownership options. Access will help navigate the details with no surprises accountability, working to determine the best solution for travel safety and efficiency. With many variables to consider, Access will logically rationalize private jet asset management for investment value and full disclosure. Aircraft acquisition and sales services will provide turnkey client solutions, working seamlessly with operations to put aircraft to work in a timely and cost efficient manner.</p>
          </Primary>
          <Callout />
        </Layout>
        <h4 className={styles.title}>Listings Index</h4>
        <ul className={styles.listings}>
          {listings.map((listing, idx) => {
            return(<ListingCard key={idx} {...listing} />);
          })}

        </ul>
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default ListingsAcquisitionsPage;
