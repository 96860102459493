import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import { Container, Content } from '../components/Layout';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Main from '../templates/Main';

import img001 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0141-02a_540x358.jpg';
import img002 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0141-03a_540x358.jpg';
import img003 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0141-04a_540x358.jpg';
import img004 from '../images/wp-uploads/2011/09/AA081210_Citation_680-0141-05a_540x358.jpg';

const images = [img001, img002, img003, img004];

function ContactUsPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation</title>
    </Helmet>
    <Header />
    <Content>
      <Container>
        <Gallery
          title="Citation Sovereign (141)"
          images={images}
        />
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default ContactUsPage;
