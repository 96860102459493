import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Layout, { Primary, CalloutListings, Container, Content } from '../components/Layout';
import Footer from '../components/Footer';
import Hero from '../components/heros/AviationFacilities';
import Main from '../templates/Main';

const items = [
  'Sale of Investment Property',
  'Acquisition Management',
  'Design & Construction Management',
  'Real Estate Administration',
  'Portfolio & Disposition Management',
];

function AviationFacilitiesPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation</title>
    </Helmet>
    <Header Banner={Hero} />
    <Content>
      <Container>
        <Layout>
          <Primary title="Aviation Facilities">
            <div>
              <p>To meet clients’ service expectations, Access is also a leader in aviation facility consulting. From new construction planning and development, to facility acquisition, lease, management, relocation and disposition, Access will deliver results with industry sector specialization. Access is committed to working diligently to understand business needs and to deliver solutions in a timely manner.</p>
              <p>Access manages:</p>
              <ul>
                {items.map((item, idx) => <li key={idx}>{item}</li>)}
              </ul>
            </div>
          </Primary>
          <CalloutListings />
        </Layout>
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default AviationFacilitiesPage
