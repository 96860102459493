import React, { useState } from 'react';
import * as styles from './Gallery.module.css';

const _images = [
  'https://placekitten.com/600/400?image=1',
  'https://placekitten.com/600/400?image=2',
  'https://placekitten.com/600/400?image=3',
  'https://placekitten.com/600/400?image=4',
  'https://placekitten.com/600/400?image=5',
  'https://placekitten.com/600/400?image=6',
];

function Thumbnail({
  url,
  title='',
  onClick,
}) {
  function clickHandler() {
    onClick(url);
  }

  return (<div
    onClick={clickHandler}
    onMouseEnter={clickHandler}
    style={{backgroundImage: `url(${url})`}}
  />);
}

function Gallery({
  title='',
  images=_images,
}) {

  const [primaryImg, setPrimaryImg] = useState(images[0]);

  return(<div className={styles.gallery}>
    <div>
      <h3 className={styles.title}>
        {title}
      </h3>
      <div className={styles.thumbs}>
      {
        images.map((image, idx) => {
          return <Thumbnail url={image} key={idx} title={idx} onClick={setPrimaryImg} />
        })
      }
      </div>
    </div>
    <div>
      <img src={primaryImg} alt={'Primary image'} style={{height: 'auto', width: '100%'}} />
    </div>
  </div>);

}

export default Gallery;
