import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Layout, { Primary, CalloutListings, Content, Container }  from '../components/Layout';
import Footer from '../components/Footer';
import Hero from '../components/heros/AircraftManagement';
import Main from '../templates/Main';

function AircraftManagementPage() {
  return (<Main>
    <Helmet>
      <title>Access Aviation - Aircraft management</title>
    </Helmet>
    <Header Banner={Hero} />
    <Content>
      <Container>
        <Layout>
          <Primary title="Aircraft Management">
            <p>Many companies have learned that streamlined organizational structures support the use of specialized consultants who efficiently manage non-core business assets. As transportation logistics become more technical and complex, the Access support group has combined resources to specialize in highly personalized on-demand domestic and international aircraft operations. Each aircraft owner will always retain full operational control of owned aircraft. Access will provide sales, administrative and operational support with full security and confidentiality.</p>
            <p>Services include aircraft partnership management and aircraft advisor solutions. Aircraft are maintained under the manufacturer’s maintenance program, and flown to NBAA IS-BAO highest standards.</p>
          </Primary>
          <CalloutListings />
        </Layout>
      </Container>
    </Content>
    <Footer />
  </Main>);
}

export default AircraftManagementPage;
