import React from 'react';
import { Helmet } from 'react-helmet';
import * as styles from '../css/reset.css';

function Main({
  children
}) {
  return(<main>
    <Helmet>
      <meta charSet="utf-8" />
    </Helmet>
    {children}
  </main>);
}

export default Main;
