import React from 'react';
import { Link } from 'gatsby';

import * as styles from './Footer.module.css';
import ImgNBAA from '../images/logo_NBAA.png';
import ImgGHP from '../images/logo_greater_houston_partnership.png';

function Footer() {
  return(<div className={styles.footer}>
    <div className={styles.container}>

      <div className={styles.primary}>
        <h3 className={styles.title}>
          Access Aviation
        </h3>

        <ul className={styles.nav}>
          <Link to="/listings-acquisitions">
            Listings & Acquisitions
          </Link>
          <Link to="/aircraft-management">
            Aircraft Management
          </Link>
          <Link to="/aviation-facilities">
            Aviation Facilities
          </Link>
          <Link to="/about-us">
            About Us
          </Link>
          <Link to="/contact-us">
            Contact Us
          </Link>
        </ul>
      </div>

      <div className={styles.secondary}>
        <div className={styles.logos}>
          <img src={ImgNBAA} alt={'Member of NBAA'} />
          <img src={ImgGHP} alt={'Member of Greater Houston Partnership'} style={{maxWidth: '110px', paddingLeft: '25px'}} />
        </div>

        <div>
          Houston TX • Phone (281) 770-7726
        </div>
      </div>
    </div>
  </div>);
}

export default Footer;

