import React, { Fragment } from 'react';
import * as styles from './Layout.module.css';
import { Link } from 'gatsby';

export function Container({
  children,
  width='960px',
}) {
  return(<div style={{width, margin: '0 auto', padding: '40px 0' }}>
    {children}
  </div>);

}

export function Content({
  children
}) {
  return(<div style={{background: 'white'}}>{children}</div>);
}

export function CalloutListings() {
  return null;
  return(<div>
    <h3 className={styles.title} style={{background: '#075798'}}>
      Recent listings
    </h3>
      <hr />
      <p>
      <Link to="/iah-corporate-aviation-facility-lease-opportunity">
        IAH Corporate Aviation Facility – Lease Opportunity
      </Link>
      </p>
      <hr />
      <p>
      <Link to="/listings-acquisitions">
        View all listings
      </Link>
      </p>
  </div>);
}

export function Callout({
  children
}) {

  const childs = React.Children.count(children);
  if (childs === 0) {
    return(<div className={styles.callout} style={{lineHeight: '1.5'}}>
      <p>With thirty years of aircraft <span style={{display: 'inline-block'}}>operations experience</span>, Access is positioned to provide clients with aircraft options that have the highest dispatch reliability and best OEM product support <span style={{display: 'inline-block'}}>in the industry.</span></p>
    </div>);
  }

  return(<div className={styles.callout}>
    {children}
  </div>);
}

export function Primary({
  title,
  children,
}) {
  return(
    <Fragment>
      <h3 className={styles.title}>
        {title}
      </h3>
      <div className={styles.description}>
        {children}
      </div>
    </Fragment>
  );
}

function Layout({
  children
}) {

  const childs = React.Children.count(children);
  if (childs === 1) {
    return(
      <div className={styles.container}>
        {children}
      </div>
    );
  }

  return(
    <div className={styles.container}>
      <div className={styles.primary}>
        {children[0]}
      </div>
      <div className={styles.secondary}>
        {children[1]}
      </div>
    </div>
  );
}

export default Layout;